@import 'styles/colors';
@import 'styles/extends';
@import 'styles/font';
@import 'styles/layout';

$desktop-view-project-columns: 2;
$print-project-column-gap: 10px;
$print-project-column-basis: ($print-viewport-width / $desktop-view-project-columns);
$print-project-column-width: $print-project-column-basis - ($print-project-column-gap / 2) - 10;

.org-project {
  align-content: flex-start;
  background-color: $tag-background-color;
  border-radius: $border-radius;
  border: 1px solid $project-summary-border-color;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 2 1 ($small-viewport-breakpoint / $desktop-view-project-columns);
  margin: $inline-sibling-margin;
  padding: $section-padding;
  position: relative;

  @media print {
    background-color: $app-print-background-color;
    border-width: 0;
    flex: 2 1 $print-project-column-basis;
    margin: $inline-sibling-margin 0;
    max-width: $print-project-column-width;
    padding: 0;

    // Allow last project in the list to span columns
    &:last-child {
      max-width: 100%;
    }
  }

  @extend %remove-button;

  .description,
  .links,
  .samples,
  .skills,
  .technologies,
  .title-section {
    flex: 2 2 100%;
    margin: 0 auto ($inline-sibling-margin * 2) auto;
    max-width: $small-viewport-breakpoint;

    &:last-child {
      margin-bottom: 0;

      @media print {
        margin-bottom: $inline-sibling-margin * 4;
      }
    }

    @media print {
      max-width: 100%;
    }
  }

  .links,
  .samples,
  .skills,
  .technologies {
    display: flex;
    justify-content: space-between;

    h5 {
      flex: 2 1 4vw;
      text-align: left;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      flex: 2 1 80%;
      justify-content: flex-start;
      margin-left: $inline-character-margin;

      li {
        &:not(:last-child) {
          &:after {
            content: '•';
            padding: $inline-character-margin;
          }
        }
      }
    }
  }

  .title-section {
    text-align: left;

    .title {
      h4 {
        margin: 0 0 $inline-character-margin 0;
      }

      div {
        font-size: $sub-base-font-size;
      }
    }

    .team-size,
    .year {
      font-size: $sub-base-font-size;
    }
  }

  .description {
    font-family: $condensed-font;
    text-align: left;
    margin-bottom: $inline-sibling-margin * 4;
  }

  .technologies {
    margin-bottom: $inline-sibling-margin * 4;
  }
}

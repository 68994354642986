@import 'styles/colors';
@import 'styles/extends';
@import 'styles/font';

.skills,
.technologies {
  text-align: center;

  @media print {
    max-width: $print-viewport-width;
  }

  .nav-skills-list,
  .nav-technologies-list {
    @extend %tag-style-list-items;
    align-content: space-around;
    display: flex;
    flex-wrap: wrap;
    font-family: $condensed-font;
    font-size: $sub-base-font-size;
    justify-content: space-between;
    margin: ($section-margin / 3) 0 $section-margin 0;
    padding: 0;
    text-shadow: 1px 1px 0 $black;

    @media print {
      font-size: $base-font-size;
      text-shadow: none;
    }
  }
}

@import 'styles/colors';
@import 'styles/font';
@import 'styles/layout';

.author-links-primary {
  margin-bottom: $inline-character-margin;

  @media print {
    flex: 1 4 25%;
    order: 4;
  }
}

.author-links-secondary {
  margin-bottom: $section-margin;
}

.author-links-primary,
.author-links-secondary {
  display: flex;
  flex-wrap: wrap;
  font-family: $condensed-font;
  justify-content: space-between;
  text-align: center;

  a {
    flex: 1 1 auto;
    text-decoration: none;

    @media print {
      text-align: right;
    }

    &:not(:last-child) {
      margin-right: $inline-character-margin * 4;

      @media print {
        margin-right: 0;
      }
    }

    svg {
      font-size: 1rem;
      margin-right: $inline-character-margin;

      &.fa-twitter {
          color: $twitter-blue;
      }

      &.fa-linkedin {
          color: $linkedin-blue;
      }

      &.fa-stack-overflow {
          color: $stack-overflow-orange;
      }

      &.fa-github {
          color: $github-grey;
      }

      &.fa-link,
      &.fa-envelope {
          color: $app-text-color;
      }
    }
  }
}

@import 'styles/colors';
@import 'styles/extends';
@import 'styles/font';
@import 'styles/layout';

.org-summary {
  background-color: $org-summary-background-color;
  margin: $section-margin 0;
  padding: $section-padding;
  position: relative;

  @media print {
    background-color: $app-print-background-color;
    padding: 0;
  }

  @extend %remove-button;

  .org-overview {
    margin-bottom: $section-margin;

    h4, h5 {
      margin: 0;
      font-family: $serif-font;
    }

    p {
      font-family: $serif-font;
      margin: ($inline-sibling-margin * 2) auto;
    }

    .employed-timespan {
      font-size: $sub-base-font-size;
    }

    .org-location {
      font-size: $sub-base-font-size;
      font-style: italic;
    }

    .org-roles-and-teams {
      display: flex;
      justify-content: space-between;
      margin: ($inline-sibling-margin * 2) auto;

      @media print {
        margin: $section-margin auto;
      }

      .org-roles {
        padding-right: ($inline-sibling-margin * 4);
        text-align: right;
      }

      .org-teams {
        padding-left: $inline-sibling-margin;
        text-align: left;
      }

      .org-roles,
      .org-teams {
        flex: 1 1 50%;

        ul {
          margin: 0;
          padding: 0;
        }
      }
    }

    .org-tasks {
      text-align: center;

      .tasks-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;

        @media print {
          justify-content: center;
        }

        li {
          &:not(:last-child) {
            &:after {
              content: '•';
              padding: $inline-character-margin;
            }
          }
        }
      }
    }
  }

  .org-projects {
    align-content: flex-start;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    h4 {
      margin: 0;
      font-family: $serif-font;
    }
  }
}

@import 'styles/colors';
@import 'styles/font';
@import 'styles/layout';

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $app-background-color;
  font-family: $serif-font;
  font-size: $base-font-size;
  text-rendering: optimizeLegibility;

  a {
    color: $app-link-color;

    &:hover {
      color: $app-visited-link-color;
    }

    &:visited {
      color: darken($app-link-color, 20%);
    }
  }

  body {
    margin: 0;
    padding: 0;

    .app {
      align-items: center;
      color: $app-text-color;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      max-width: $body-content-width;
      min-height: 100vh;

      @media only screen and (max-width: $small-viewport-breakpoint) {
        max-width: 95%;
      }
    }
  }

  code {
    font-family: $monospace-font;
  }

  h1, h2, h3, h4, h5 {
    font-family: $sans-serif-font-smallcaps;
    margin: 0;
  }

  // unbolded headers
  h2, h3, h4 {
    font-weight: normal;
  }

  h1 {
    font-size: $h1-font-size;
  }

  h2 {
    font-size: $h2-font-size;
  }

  h3 {
    font-family: $serif-font;
    font-size: $h3-font-size;
  }

  h4 {
    font-size: $h4-font-size;
    line-height: 1em;
  }

  h5 {
    font-size: $h5-font-size;
    font-weight: bold;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  @media only screen and (max-width: $small-viewport-breakpoint) {
    font-size: $small-base-font-size;

    h1 {
      font-size: $h1-font-size;
    }

    h2 {
      font-size: $h2-font-size;
    }

    h3 {
      font-size: $h3-font-size;
    }

    h4 {
      font-size: $h4-font-size;
    }

    h5 {
      font-size: $h5-font-size;
    }
  }

  .print-show {
    display: none;
  }
}

@media print {
  html {
    background-color: $app-print-background-color;
    margin: 0;
    width: 100%;

    a {
      color: $app-print-text-color;

      &:hover,
      &:visited {
        color: $app-print-text-color;
      }
    }

    body {
      .app {
        color: $app-print-text-color;
        max-width: 100%;
      }
    }
  }

  .print-hide {
    display: none !important;
  }

  .print-show {
    display: inline-block !important;
  }
}

$color1: #8b6283;
$color2: #bd94b4;
$color3: #392939;
$color4: #ffffff;
$color5: #dedede;
$color6: #734a6a;
$color7: #b42910;
$color8: #de5239;

$black: #282c34;
$blue: #add8e6;
$github-grey: #d3d3d3;
$linkedin-blue: #3371b7;
$stack-overflow-orange: #f69c55;
$twitter-blue: #4da7de;
$violet: #d8bfd8;
$white: #FFFFFF;

$app-background-color: $black;
$app-link-color: $blue;
$app-text-color: $white;
$app-text-muted-color: $github-grey;
$app-text-note: $github-grey;
$app-visited-link-color: $violet;

// print colors
$app-print-background-color: $white;
$app-print-text-color: #000000;

// calculated colors
$tag-background-color: lighten($app-background-color, 20%);
$org-summary-background-color: lighten($app-background-color, 10%);
$project-summary-border-color: darken($tag-background-color, 20%);

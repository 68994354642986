@import 'colors';
@import 'layout';

%tag-style-list-items {
  flex-wrap: wrap;
  font-size: 1rem;
  justify-content: space-between;
  padding: 0;

  li {
    background-color: $tag-background-color;
    border-radius: $border-radius;
    display: flex;
    margin: 5px;
    padding: 5px;
    cursor: default;
    border: 1px solid $tag-background-color;

    // For now, these should not behave as buttons because they have no actions, yet
    // cursor: pointer;
    // user-select: none;

    @media print {
      background-color: $app-print-background-color;
      padding: 0;
      border: none;
    }

    &:active {
      background-color: $blue;
    }

    &:hover {
      background-color: transparent;

      &.off-state {
        background-color: $tag-background-color;
      }
    }

    &.off-state {
      background-color: transparent;
    }
  }
}

%remove-button {
  &:hover {
    > .remove-button {
      opacity: 0.25;
    }
  }

  > .remove-button {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    right: $section-padding / 4;
    top: $section-padding / 4;

    &:hover {
      opacity: 1;
    }
  }
}
